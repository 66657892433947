import React from 'react';
import { Paper, Typography } from '@mui/material';

function ComTransComp() {
  return (
    <Paper
      sx={{
        padding: '10px 15px', // Adjusted padding for a smaller look
        marginBottom: '20px',
        width: 'fit-content', // Makes the paper fit the content
        '@keyframes slide-up': {
          '0%': {
            transform: 'translateY(20px)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        },
        animation: 'slide-up 0.5s ease-in-out',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Agent Commission Transactions
      </Typography>
      {/* Add other settings buttons or inputs here */}
    </Paper>
  );
}

export default ComTransComp;