import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';    

function MakeDepComp() {
  const [clients, setClients] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [depositedAmount, setDepositedAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchClients();
    fetchCurrencies();
  }, []);

  const fetchClients = async () => {
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.get('https://api.nkolozi.com/api/Agent/clients', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 'Request was successfull') {
        setClients(response.data.data);
      } else {
        console.error('Error fetching clients:', response.data.message);
        setError('Error fetching clients');
      }
    } catch (error) {
      console.error('Error fetching clients:', error.response || error.message || error);
      setError('Error fetching clients');
    }
  };

  const fetchCurrencies = async () => {
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 'Request was successfull') {
        setCurrencies(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedCurrency(response.data.data[0].id);
        }
      } else {
        console.error('Error fetching currencies:', response.data.message);
        setError('Error fetching currencies');
      }
    } catch (error) {
      console.error('Error fetching currencies:', error.response || error.message || error);
      setError('Error fetching currencies');
    }
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('agent_token');
      const formData = new FormData();
      formData.append('client_id', selectedClient);
      formData.append('currency_id', selectedCurrency);
      formData.append('amount', depositedAmount);

      const response = await axios.post(
        'http://api.nkolozi.com/api/Agent/make-client-deposit',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === 200) {
        setSuccess('Deposit made successfully');
        setSelectedClient('');
        setSelectedCurrency('');
        setDepositedAmount('');
      } else {
        console.error('Failed to Make Deposit:', response);
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Failed to Make Deposit:', error.response || error.message || error);
      setError('Failed to Make Deposit: ' + (error.response?.data?.message || error.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <Card>
        <Card.Header>
       
<div style={{ marginLeft: '40%' }}>
 <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          justifyContent: 'center',
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Make Deposit
        </Typography>
      </Paper>
      </div>
        </Card.Header>
        
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId="clientSelect" className="mb-3">
                  <Form.Label>Client</Form.Label>
                  <Form.Control as="select" value={selectedClient} onChange={handleClientChange} required>
                    <option value="">Select a client</option>
                    {clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.name} {client.surname} ({client.phone1})
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="currencySelect" className="mb-3">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCurrency}
                    onChange={handleCurrencyChange}
                    required
                  >
                    <option value="">Select a currency</option>
                    {currencies.map((currency) => (
                      <option key={currency.id} value={currency.id}>
                        {currency.name} 
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="depositedAmount" className="mb-3">
                  <Form.Label>Deposited Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={depositedAmount}
                    onChange={(e) => setDepositedAmount(e.target.value)}
                    min={1}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" disabled={loading} style={{width: '100px', marginLeft:'50%'}}>
              {loading ? 'Loading...' : 'Submit'}
            </Button>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
            {success && <div className="alert alert-success mt-3">{success}</div>}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MakeDepComp;
