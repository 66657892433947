import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Card, Alert, Spinner } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';     


const SettingsComp = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/change-password',
        {
          old: oldPassword,
          new: newPassword,
          confirm: confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResponseMessage('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error);
      setResponseMessage('Failed to change password',error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container style={{ marginTop: '5%' }}>
      <Card style={{ width: '50%', margin: 'auto', padding: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <Card.Body>
          <Card.Title>
          <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Change Password
        </Typography>
      </Paper>
          </Card.Title>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="oldPassword" className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                size="sm"
              />
            </Form.Group>

            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                size="sm"
              />
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                size="sm"
              />
            </Form.Group>

            <Button variant="primary" size="sm" type="submit" disabled={submitting} className="w-100">
              {submitting ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                'Change Password'
              )}
            </Button>
          </Form>
          {responseMessage && (
            <Alert variant="info" className="mt-3">
              {responseMessage}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SettingsComp;
