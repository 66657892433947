import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paper, Typography } from '@mui/material';

const ConvertCTFloatComp = () => {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [amount, setAmount] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch the list of currencies from the API
  const fetchCurrencies = async () => {
    try {
      const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`, // Replace with your auth mechanism
        },
      });
      setCurrencies(response.data.data);
    } catch (err) {
      setError('Failed to fetch currencies. Please try again later.');
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setError(null);

    if (!amount || !selectedCurrency) {
      setError('Please enter an amount and select a currency.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/cashin-to-float-transfer',
        {
          amount: amount,
          currency_id: selectedCurrency,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`, // Replace with your auth mechanism
          },
        }
      );

      setResponseMessage(response.data.message || 'Transfer successful');
    } catch (err) {
      setError('Failed to complete transfer. Please try again.');
    }

    setLoading(false);
  };

  return (
    <div style={{ marginLeft: '20%', marginTop: '2%' }}>
      <div className="card" style={{ width: '24rem', margin: 'auto' }}>
      <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Convert Cash in To Float
        </Typography>
      </Paper>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="amount">Amount:</label>
              <input
                type="number"
                className="form-control"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="currency">Currency:</label>
              <select
                className="form-control"
                id="currency"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                required
              >
                <option value="">Select currency</option>
                {currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.name}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="btn btn-primary mt-3 w-100" disabled={loading}>
              {loading ? 'Processing...' : 'Convert'}
            </button>
          </form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          {responseMessage && <div className="alert alert-success mt-3">{responseMessage}</div>}
        </div>
      </div>
    </div>
  );
};

export default ConvertCTFloatComp;
