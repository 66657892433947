import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function CommissionsComp() {
  const [commissions, setCommissions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCommissions = async () => {
      try {
        const token = localStorage.getItem('agent_token');
        if (!token) {
          throw new Error('No token found in local storage');
        }
        const response = await axios.get('https://api.nkolozi.com/api/Agent/commissions', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status !== 200) {
          throw new Error(`Unexpected response status: ${response.status}`);
        }

        setCommissions(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        let message = 'Error fetching commissions';
        if (error.response) {
          // Server responded with a status other than 200 range
          message += `: ${error.response.status} ${error.response.statusText}`;
          console.error('Error response data:', error.response.data);
        } else if (error.request) {
          // Request was made but no response was received
          message += ': No response from server';
          console.error('Error request:', error.request);
        } else {
          // Something else happened while setting up the request
          message += `: ${error.message}`;
          console.error('Error message:', error.message);
        }
        setErrorMessage(message);
        console.error('Error fetching commissions:', error);
      }
    };

    fetchCommissions();
  }, []);

  return (
    <div className="container mt-4" style={{ marginLeft: '250px' }}>
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title">Commissions</h2>
          {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
          {commissions.length === 0 ? (
            <div className="alert alert-info" role="alert">No commissions available.</div>
          ) : (
            <div className="table-responsive">
              <table className="table table-sm table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Commission ID</th>
                    <th>Commission Amount</th>
                    <th>Commission Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {commissions.map(commission => (
                    <tr key={commission.id}>
                      <td>{commission.id}</td>
                      <td>${commission.amount}</td>
                      <td>{new Date(commission.date).toLocaleDateString()}</td>
                      <td>{commission.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommissionsComp;
