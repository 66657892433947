import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';

function DepositsComp() {
  const [deposits, setDeposits] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [clientAccounts, setClientAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState('');

  // Format the date to d-m-Y
  const formatDate = (date) => {
    if (!date) return '';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options).replace(/\//g, '-');
  };

  const fetchDeposits = async () => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('agent_token'); // Ensure this token is valid and not null

      // Format dates
      const formattedFromDate = formatDate(fromDate);
      const formattedToDate = formatDate(toDate);

      // Check if dates are valid
      if (!formattedFromDate || !formattedToDate) {
        setError('Invalid date format. Please use a valid date.');
        setLoading(false);
        return;
      }

      console.log('Formatted Dates:', formattedFromDate, formattedToDate);

      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/clients-deposits',
        {
          from_date: formattedFromDate,
          to_date: formattedToDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('API Response:', response.data);

      if (response.status === 200 && response.data.status === 'Request was successfull') {
        setDeposits(response.data.data);
      } else {
        setError(response.data.message || 'Unexpected response status');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'Error fetching deposit data');
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError('Error fetching deposit data');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchClientAccounts = async (clientId) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.get(
        `https://api.nkolozi.com/api/Agent/client-accounts/${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Client Accounts Response:', response.data);

      if (response.status === 200 && response.data.status === 'Request was successfull') {
        setClientAccounts(response.data.data);
      } else {
        setError(response.data.message || 'Unexpected response status');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'Error fetching client accounts');
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError('Error fetching client accounts');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = async (imageUrl) => {
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
      const imageBlob = URL.createObjectURL(response.data);
      setModalImageUrl(imageBlob);
      setShowModal(true);
    } catch (error) {
      setError('Error fetching image');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageUrl('');
  };

  const handleShowRejectModal = (id) => {
    setTransactionId(id);
    setShowRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
    setRejectReason('');
    setTransactionId('');
  };

  const handleReject = async () => {
    try {
      const token = localStorage.getItem('agent_token');
    
      await axios.post(
        'https://api.nkolozi.com/api/Admin/reject-deposit-transaction',
        {
          deposit_id: transactionId,
          reason: rejectReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      fetchDeposits();
      handleCloseRejectModal();
    } catch (error) {
      setError('Error rejecting transaction');
    }
  };

  const handleProceed = async (id) => {
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.get(`https://api.nkolozi.com/api/Admin/proceed-deposit-transaction/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Proceed response:', response.data);
      if (response.status === 200 && response.data.status === 'Request was successfull') {
        fetchDeposits();
      } else {
        setError(response.data.message || 'Unexpected response status');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'Error proceeding transaction');
      } else if (error.request) {
        setError('No response received from the server.');
      } else {
        setError('Error proceeding transaction');
      }
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      fetchDeposits();
    }
  }, [fromDate, toDate]);

  return (
    <div style={{ marginLeft: '20%', marginTop: '4%', width: '75%' }}>
      <Card>
        <Card.Header>
          <h1>Deposits For My Clients</h1>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="fromDate" className="mb-3">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="toDate" className="mb-3">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex align-items-end">
                <Button variant="primary" onClick={fetchDeposits} disabled={loading}>
                  {loading ? 'Loading...' : 'Fetch Deposits'}
                </Button>
              </Col>
            </Row>
          </Form>

          {error && <div className="alert alert-danger mt-3">{error}</div>}

          <Form.Group controlId="clientAccounts" className="mb-3">
            <Form.Label>Select Client Account</Form.Label>
            <Form.Control
              as="select"
              value={selectedAccountId}
              onChange={(e) => setSelectedAccountId(e.target.value)}
            >
              <option value="">Select an Account</option>
              {clientAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.id} - {account.currency_id} - {account.available_balance}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" onClick={() => fetchClientAccounts('937426664')} disabled={loading}>
            {loading ? 'Loading...' : 'Fetch Client Accounts'}
          </Button>

          <div className="mt-4" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {deposits.length > 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Reference Number</th>
                    <th>Payment Proof</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {deposits.map((deposit) => (
                    <tr key={deposit.id}>
                      <td>{deposit.id}</td>
                      <td>{deposit.amount}</td>
                      <td>{deposit.type}</td>
                      <td>{deposit.status}</td>
                      <td>{deposit.reference_number}</td>
                      <td>
                        {deposit.payment_proof && (
                          <Button
                            variant="link"
                            onClick={() => handleShowModal(deposit.payment_proof)}
                          >
                            View Proof
                          </Button>
                        )}
                      </td>
                      <td>{deposit.created_at}</td>
                      <td>{deposit.updated_at}</td>
                      <td>
                        <Button
                          variant="success"
                          onClick={() => handleProceed(deposit.id)}
                          disabled={deposit.status !== 'Pending'}
                        >
                          Proceed
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => handleShowRejectModal(deposit.id)}
                          disabled={deposit.status !== 'Pending'}
                          className="ml-2"
                        >
                          Reject
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No deposits found for the selected date range.</p>
            )}
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Payment Proof</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImageUrl} alt="Payment Proof" style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRejectModal} onHide={handleCloseRejectModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reject Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="rejectReason">
            <Form.Label>Reason for rejection</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRejectModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DepositsComp;
