import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import ManageCashComp from '../Components/ManageCashComp'

function ManageCash() {
  return (
    <div>
     
      <SideNav/>
      <ManageCashComp/>
    </div>
  )
}

export default ManageCash
