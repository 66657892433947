import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ClientSelector({ onClientSelect }) {
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/clients', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        console.log('Clients fetched:', response.data.data);
        setClients(response.data.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClientId(clientId);
    onClientSelect(clientId);
    console.log('Selected client ID:', clientId);
  };

  return (
    <div className='form-control '>
      <label htmlFor="clientSelect" className='form-label'>Select Client:</label>
      <select id="clientSelect" className='form-control' value={selectedClientId} onChange={handleClientChange}>
        <option value="">--Select Client--</option>
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name} {client.surname}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ClientSelector;
