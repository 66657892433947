import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import CreateUserComp from '../Components/CreateUserComp'

function CreateUser() {
  return (
    <div>
      
      <SideNav/>
      <CreateUserComp/>
    </div>
  )
}

export default CreateUser
