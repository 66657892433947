import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import UpdateUserComp from '../Components/UpdateUserComp'

function UpdateUser() {
  return (
    <div>
    
      <SideNav/>
      <UpdateUserComp/>
    </div>
  )
}

export default UpdateUser
