import React from 'react'
import ChangeCurrComp from '../Components/ChangeCurrComp'
import SideNav from '../Components/SideNav'

const ChangeCurrency = () => {
  return (
    <div>
    <SideNav/>
  <ChangeCurrComp/>
  </div>
  )
}

export default ChangeCurrency