import React, { useState } from 'react';
import '../Components/Css/SupportComp.css';

function SupportComp() {
  const [messages, setMessages] = useState([
    { id: 1, text: 'Welcome to Customer Support. How can we assist you today?', sender: 'agent' },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    if (newMessage.trim() === '') return; // Prevent empty messages

    const newMessageObj = {
      id: messages.length + 1,
      text: newMessage,
      sender: 'agent', // Assuming agent sends the message initially
    };

    setMessages([...messages, newMessageObj]);
    setNewMessage('');

    // Simulate response after a delay (replace with actual logic)
    setTimeout(() => {
      handleCustomerResponse();
    }, 1000);
  };

  const handleCustomerResponse = () => {
    const customerResponse = {
      id: messages.length + 1,
      text: 'Thank you for your response! Could you please provide more details about...',
      sender: 'customer',
    };

    setMessages([...messages, customerResponse]);
  };

  const handleChange = (event) => {
    setNewMessage(event.target.value);
  };

  return (
    <div className="support-container">
      <div className="chat-container">
        <div className="chat">
          {messages.map((message) => (
            <div key={message.id} className={`message ${message.sender}`}>
              <div className="message-content">{message.text}</div>
            </div>
          ))}
        </div>
        <form onSubmit={handleMessageSubmit} className="message-input">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={handleChange}
            className="input-field"
          />
          <button type="submit" className="send-button">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default SupportComp;
