import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import ReportsComp from '../Components/ReportsComp'

function Reports() {
  return (
    <div>

      <SideNav/>
      <ReportsComp/>
    </div>
  )
}

export default Reports
