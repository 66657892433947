import React from 'react'

function MakeTransComp() {
  return (
    <div>
      <h1>Make Trans</h1>
    </div>
  )
}

export default MakeTransComp
