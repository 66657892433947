import React from 'react'
import SideNav from '../Components/SideNav'
import CashoutComp from '../Components/CashoutComp'

function Cashout() {
  return (
    <div>
     <SideNav/>
     <CashoutComp/> 
    </div>
  )
}

export default Cashout
