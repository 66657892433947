import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import InnerBankComp from '../Components/InnerBankComp'

function InnerBank() {
  return (
    <div>
    
     <SideNav/>
     <InnerBankComp/> 
    </div>
  )
}

export default InnerBank
