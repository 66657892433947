import React from 'react'
import MakeTransComp from '../Components/MakeTransComp'

function MakeTrans() {
  return (
    <div>
     <MakeTransComp/> 
    </div>
  )
}

export default MakeTrans
