import React, { useState } from 'react';
import '../Components/Css/ManageCashComp.css';

function ManageCashComp() {
  const [depositAmount, setDepositAmount] = useState('');
  const [branch, setBranch] = useState('');
  const [cashHoldingLimit, setCashHoldingLimit] = useState(10000); // Example holding limit
  const [currentBalance, setCurrentBalance] = useState(8000); // Example current balance
  const [depositHistory, setDepositHistory] = useState([
    { date: '2024-06-01', amount: 2000, branch: 'Branch 1' },
    { date: '2024-06-15', amount: 3000, branch: 'Branch 2' },
  ]); // Example history

  const handleDeposit = (e) => {
    e.preventDefault();
    const newBalance = currentBalance + parseFloat(depositAmount);
    if (newBalance > cashHoldingLimit) {
      alert('Cash holding limit exceeded! Please make a deposit to the bank.');
    } else {
      setCurrentBalance(newBalance);
      setDepositHistory([
        ...depositHistory,
        { date: new Date().toLocaleDateString(), amount: depositAmount, branch },
      ]);
      setDepositAmount('');
      setBranch('');
    }
  };

  return (
    <div className="manage-cash-container">
      <div className="cash-balance-card">
        <h2>Current Cash Balance</h2>
        <p>${currentBalance}</p>
        <p>Holding Limit: ${cashHoldingLimit}</p>
        {currentBalance > cashHoldingLimit && (
          <p className="warning">Warning: Cash holding limit exceeded!</p>
        )}
      </div>
      <h1>Manage Cash</h1>
      <form onSubmit={handleDeposit} className="manage-cash-form">
        <label htmlFor="depositAmount">Deposit Amount:</label>
        <input
          type="number"
          id="depositAmount"
          value={depositAmount}
          onChange={(e) => setDepositAmount(e.target.value)}
          required
        />
        <label htmlFor="branch">Branch:</label>
        <select
          id="branch"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
          required
        >
          <option value="">Select Branch</option>
          <option value="Branch 1">Branch 1</option>
          <option value="Branch 2">Branch 2</option>
          <option value="Branch 3">Branch 3</option>
        </select>
        <button type="submit" className="submit-button">Record Deposit</button>
      </form>
      <div className="deposit-history">
        <h2>Deposit History</h2>
        <table className="deposit-history-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Branch</th>
            </tr>
          </thead>
          <tbody>
            {depositHistory.map((entry, index) => (
              <tr key={index}>
                <td>{entry.date}</td>
                <td>${entry.amount}</td>
                <td>{entry.branch}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageCashComp;
