import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransStatusComp from '../Components/TransStatusComp'

function TransStatus() {
  return (
    <div>

      <SideNav/>
      <TransStatusComp/>
    </div>
  )
}

export default TransStatus
