import React, { useState } from 'react';
import '../Components/Css/UpdateUserComp.css';

function UpdateUserComp() {
  const [searchType, setSearchType] = useState('fullName'); // Default search type
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // Track selected user for modal

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Perform search logic (simulated here)
    // Replace with actual search implementation (e.g., API call)
    if (searchQuery.trim() !== '') {
      // Simulated search results
      setSearchResults([
        { id: 1, fullName: 'John Doe', phoneNumber: '123-456-7890', email: 'john.doe@example.com' },
        { id: 2, fullName: 'Jane Smith', phoneNumber: '987-654-3210', email: 'jane.smith@example.com' },
        // Add more search results as needed
      ]);
    } else {
      setSearchResults([]);
    }
  };

  const handleUpdateUser = (userId) => {
    // Find the selected user by ID
    const user = searchResults.find(user => user.id === userId);
    if (user) {
      setSelectedUser(user);
    }
  };

  const handleCloseModal = () => {
    setSelectedUser(null); // Reset selected user when modal is closed
  };

  const handleSaveChanges = () => {
    // Handle save changes logic (simulated here)
    console.log('Saving changes for user:', selectedUser);
    // Close modal after saving changes
    handleCloseModal();
  };

  return (
    <div className="update-user-container">
      <h1>Update User</h1>

      {/* Search Form */}
      <form onSubmit={handleSearchSubmit} className="search-form">
        <label htmlFor="searchType">Search By:</label>
        <select
          id="searchType"
          value={searchType}
          onChange={handleSearchTypeChange}
          className="search-select"
        >
          <option value="fullName">Full Name</option>
          <option value="phoneNumber">Phone Number</option>
          {/* Add more search options as needed */}
        </select>

        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder={`Enter ${searchType === 'fullName' ? 'Full Name' : 'Phone Number'}`}
          className="search-input"
        />

        <button type="submit" className="search-button">Search</button>
      </form>

      {/* Search Results Table */}
      <div className="search-results">
        <h2>Search Results</h2>
        <table className="results-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.fullName}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.email}</td>
                <td>
                  <button onClick={() => handleUpdateUser(user.id)} className="update-button">Update</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Update User */}
      {selectedUser && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Edit User: {selectedUser.fullName}</h2>
            <form onSubmit={handleSaveChanges}>
              {/* Edit user fields */}
              <label htmlFor="editFullName">Full Name:</label>
              <input
                type="text"
                id="editFullName"
                name="editFullName"
                value={selectedUser.fullName}
                onChange={(e) => setSelectedUser({ ...selectedUser, fullName: e.target.value })}
                required
                className="form-input"
              />

              <label htmlFor="editPhoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="editPhoneNumber"
                name="editPhoneNumber"
                value={selectedUser.phoneNumber}
                onChange={(e) => setSelectedUser({ ...selectedUser, phoneNumber: e.target.value })}
                required
                className="form-input"
              />

              <label htmlFor="editEmail">Email:</label>
              <input
                type="email"
                id="editEmail"
                name="editEmail"
                value={selectedUser.email}
                onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                required
                className="form-input"
              />

              <button type="submit" className="save-button">Save Changes</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateUserComp;
