import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Button } from 'react-bootstrap';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Assuming #root is your app's root element

function FindUserComp() {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    surname: '',
    lastname: '',
    country: '',
    dob: '',
    gender: '',
    national_id: '',
    phone1: '',
    email: ''
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const token = localStorage.getItem('agent_token');
        const response = await axios.get(
          'https://api.nkolozi.com/api/Agent/clients',
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('API response:', response.data); // Log the API response
        const clientsData = Array.isArray(response.data.data) ? response.data.data : [];
        setClients(clientsData);
        setFilteredClients(clientsData);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClients([]);
        setFilteredClients([]);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const filtered = clients.filter(client => 
      `${client.name} ${client.surname} ${client.lastname}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [searchTerm, clients]);

  const handleEditClick = (client) => {
    setEditingClient(client);
    setFormData({
      id: client.id,
      name: client.name,
      surname: client.surname,
      lastname: client.lastname,
      country: client.country,
      dob: client.dob,
      gender: client.gender,
      national_id: client.national_id,
      phone1: client.phone1,
      email: client.email
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('agent_token');
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/update-client-details',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('Update response:', response.data); // Log the update response
      // Update the client in the local state
      setClients((prevClients) =>
        prevClients.map((client) =>
          client.id === formData.id ? { ...client, ...formData } : client
        )
      );
      setFilteredClients((prevClients) =>
        prevClients.map((client) =>
          client.id === formData.id ? { ...client, ...formData } : client
        )
      );
      setShowModal(false); // Close the modal after submission
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  return (
    <div className="container mt-4" style={{ marginLeft: '250px' }}>
      <div className="card mb-4">
        <div className="card-body">
          <h1 className="card-title">Clients</h1>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search clients by full name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ maxWidth: '400px', marginBottom: '20px' }}
            />
          </div>

          {/* Display Clients in Table */}
          {filteredClients.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-hover table-striped table-bordered">
                <thead className="table-primary">
                  <tr>
                    <th>Client ID</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClients.map((client) => (
                    <tr key={client.id}>
                      <td>{client.id}</td>
                      <td>{`${client.name} ${client.surname} ${client.lastname}`}</td>
                      <td>{client.email}</td>
                      <td>{client.phone1}</td>
                      <td>
                        <button
                          className="btn btn-warning btn-sm"
                          onClick={() => handleEditClick(client)}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">No clients found.</div>
          )}
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Update Client Details"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            padding: '20px'
          }
        }}
      >
        <h2>Update Client Details</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Surname</label>
              <input
                type="text"
                className="form-control"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Lastname</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Country</label>
              <input
                type="text"
                className="form-control"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Date of Birth</label>
              <input
                type="date"
                className="form-control"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Gender</label>
              <input
                type="text"
                className="form-control"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>National ID</label>
              <input
                type="text"
                className="form-control"
                name="national_id"
                value={formData.national_id}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group col-md-6">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                name="phone1"
                value={formData.phone1}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button type="submit" className="btn btn-primary">
            Save Changes
          </Button>
          <Button onClick={() => setShowModal(false)} className="btn btn-secondary ml-2">
            Cancel
          </Button>
        </form>
      </Modal>
    </div>
  );
}

export default FindUserComp;
