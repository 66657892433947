import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TransStatusComp() {
  const [transactions, setTransactions] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (fromDate && toDate) {
      fetchTransactions();
    }
  }, [fromDate, toDate]);

  const fetchTransactions = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Agent/transactions', {
        from_date: fromDate,
        to_date: toDate,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`, // Assuming you store the token in localStorage
        },
      });
      setTransactions(response.data);
    } catch (err) {
      setError('Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4" style={{ marginLeft: '250px' }}>
      <h1>Transactions of this Agent</h1>
      <div className="form-group">
        <label>From Date:</label>
        <input
          type="date"
          className="form-control"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>To Date:</label>
        <input
          type="date"
          className="form-control"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
      </div>
      <button className="btn btn-primary mt-3" onClick={fetchTransactions} disabled={loading}>
        Fetch Transactions
      </button>
      
      {loading && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}

      <div className="mt-4">
        {transactions.length > 0 ? (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{transaction.id}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No transactions found for the selected dates.</p>
        )}
      </div>
    </div>
  );
}

export default TransStatusComp;
