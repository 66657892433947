import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import FindUserComp from '../Components/FindUserComp'

function FindUser() {
  return (
    <div>
    
      <SideNav/>
      <FindUserComp/>
    </div>
  )
}

export default FindUser
