import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import CommissionsComp from '../Components/CommissionsComp'

function Commissions() {
  return (
    <div>
 
      <SideNav/>
      <CommissionsComp/>
    </div>
  )
}

export default Commissions
