import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function Nav() {
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('agent_token');
    if (!storedToken) {
      console.log("token not set");
      navigate('/');
    } else {
      console.log(storedToken);
    }
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('agent_token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Application-Type': 'application/json',
      };

      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/agent', { headers });
        setUser(response.data.data.name);

        console.log(response.data.data.name);
        if (response.data.data.status !== 'active' || response.data.data.user_type !== 'agent') {
          await handleLogout();
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching Agent details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('agent_token');
    console.log(token)
    try {
      await fetch('https://api.nkolozi.com/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      localStorage.removeItem('agent_token');
     // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate('/');
    } catch (error) {
      console.error('An error occurred during logout', error);
    }
  };

  
  

  return (
    <div>
      <nav className="sb-topnav navbar navbar-expand navbar-dark fixed-top" style={{ backgroundColor: '#b03a30' }}>
        {/* Navbar Brand */}
        <a className="navbar-brand ps-3" href="index.html">Nkolozi Money Transfer : {user}</a>
        {/* Sidebar Toggle */}

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div className="input-group">

          </div>
        </form>
        {/* Navbar */}
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw" /></a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href="#!">Settings</a></li>
              <li><a className="dropdown-item" href="#!">Activity Log</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href="#!" >Logout</a></li>
            </ul>
          </li>
        </ul>
      </nav>

      

      <Outlet />
    </div>
  );
}

export default Nav;
