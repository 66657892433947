import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BeneficiarySelector({ clientId, onBeneficiarySelect }) {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      if (!clientId) return;
      try {
        const response = await axios.get(`https://api.nkolozi.com/api/Agent/get-beneficiaries/${clientId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        console.log('Beneficiaries fetched for client ID:', clientId, response.data.data);
        setBeneficiaries(response.data.beneficiaries);
        console.log(response)
      } catch (error) {
        console.error('Error fetching beneficiaries:', error);
      }
    };

    fetchBeneficiaries();
  }, [clientId]);

  const handleBeneficiaryChange = (event) => {
    const beneficiaryId = event.target.value;
    setSelectedBeneficiaryId(beneficiaryId);
    onBeneficiarySelect(beneficiaryId);
    console.log('Selected beneficiary ID:', beneficiaryId);
  };

  return (
    <div className='form-control'>
      <label htmlFor="beneficiarySelect">Select Beneficiary:</label>
      <select
        id="beneficiarySelect"
        value={selectedBeneficiaryId}
        onChange={handleBeneficiaryChange}
        disabled={!clientId}
        className='form-control'
      >
        <option value="">--Select Beneficiary--</option>
        {beneficiaries.length > 0 ? (
          beneficiaries.map((beneficiary) => (
            <option key={beneficiary.id} value={beneficiary.id}>
              {beneficiary.full_name}
            </option>
          ))
        ) : (
          <option disabled>No beneficiaries available</option>
        )}
      </select>
    </div>
  );
}

export default BeneficiarySelector;
