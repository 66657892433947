import React from 'react'
import SideNav from '../Components/SideNav'
import Main from '../Components/Main'

function Dashboard() {
  return (
    <div style={{ marginTop: '5%' , marginLeft: '1%'}}>
  
    <SideNav/>
    <Main/>
    </div>
  )
}

export default Dashboard
