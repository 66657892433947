import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut, Pie } from 'react-chartjs-2';
import SideNav from './SideNav';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Paper, Typography } from '@mui/material';
import CashinvsCashout from './CashinvsCashout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement);

function Main() {
  const [details, setDetails] = useState([]);
  const [cashout, setCashout] = useState('');
  const [cashin, setCashin] = useState('');
  const [currencies, setCurrencies] = useState({}); // Store currencies in an object for quick lookup
  const [isCollapsed, setIsCollapsed] = useState(false);


  // New state for pie chart data
  const [nkoloziStackTotal, setNkoloziStackTotal] = useState(0);
  const [userStackTotal, setUserStackTotal] = useState(0);

  const navigate =  useNavigate()

  function handleCashInNav(){
    navigate('/makeDeposit');
  }
  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const currencyResponse = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        
        const currencyMap = {};
        currencyResponse.data.data.forEach(currency => {
          currencyMap[currency.id] = currency.name; // Map currency ID to name
        });
        
        setCurrencies(currencyMap);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    }

    async function fetchAgentAccount() {
      try {
        const userResponse = await axios.get('https://api.nkolozi.com/api/Agent/agent', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });

        const userId = userResponse.data.data.id;

        const response = await axios.get(`https://api.nkolozi.com/api/Agent/agent-accounts/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });

        if (Array.isArray(response.data.agent_accounts)) {
          setDetails(response.data.agent_accounts);
          setCashout(response.data.agent_accounts[0].cashout_amount);
          setCashin(response.data.agent_accounts[0].cashin_user_stack);


          const agentAccounts = response.data.agent_accounts;
          // Calculate the total cashin and cashout amounts
          const totalCashin = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_user_stack), 0);
          const totalCashout = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashout_amount), 0);

          // Calculate totals for Pie Chart
          const totalNkoloziStack = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_nkolozi_stack), 0);
          const totalUserStack = agentAccounts.reduce((sum, account) => sum + parseFloat(account.cashin_user_stack), 0);
          setCashin(totalCashin);
          setCashout(totalCashout);
          setNkoloziStackTotal(totalNkoloziStack);
          setUserStackTotal(totalUserStack);

          
        } else {
          console.error('Unexpected data format:', response.data.agent_accounts);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchCurrencies();
    fetchAgentAccount();
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  

  // Updated Cash Reconciliation data
  const cashReconciliationData = {
    labels: details.map((_, index) => `Account ${index + 1}`), // Dynamic labels based on the number of accounts
    datasets: [
      {
        label: 'Cash In',
        data: details.map(account => parseFloat(account.cashin_user_stack)),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        fill: false,
      },
      {
        label: 'Cash Out',
        data: details.map(account => parseFloat(account.cashout_amount)),
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        fill: false,
      },
    ],
  };

  // Updated Transaction Status data
  const transactionStatusData = {
    labels: ['Nkolozi Stack', 'User Stack'],
    datasets: [
      {
        data: [nkoloziStackTotal, userStackTotal],
        backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 1)'],
        hoverBackgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(255, 206, 86, 0.8)'],
      },
    ],
  };

  return (
    <div>
      <SideNav isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        id="layoutSidenav_content"
        style={{
          flex: 1,
          marginLeft: isCollapsed ? '80px' : '250px',
          transition: 'margin-left 0.3s',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <main style={{ flex: 1, overflow: 'auto' }}>
          <div className="container-fluid px-5">
            <div className="row">
              <div className="container mt-4">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Agent Dashboard
        </Typography>
      </Paper>
    </div>
    </div>

<div>
  <CashinvsCashout/>
</div>

                     {/* here */}

                <div className="container mt-4">
                  <div className="container mt-4">
                    <div className="card border-0 shadow-lg rounded-lg">
                      <div className="card-header text-white text-center py-4" style={{ background: 'linear-gradient(135deg, #003366, #3366cc)', position: 'relative' }}>
                        <div
                          className="bg-primary text-white d-flex align-items-center justify-content-center shadow-lg"
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
                            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                            position: 'absolute',
                            top: '20px',
                            left: '10px',
                            fontSize: '0.8rem'
                          }}
                        >
                          <div className="text-center">
                            <h6 className="mb-1">Welcome</h6>
                            
                          </div>
                        </div>
                        <h4 className="mb-0">Agent Float Balances</h4>
                        <div
                          className="bg-danger text-white d-flex align-items-center justify-content-center shadow-lg"
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.15) 100%)',
                            boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                            position: 'absolute',
                            top: '20px',
                            right: '10px',
                            fontSize: '0.8rem'
                          }}
                        >
                          <div className="text-center">
                            <h6 className="mb-1">Agent</h6>
                            
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-4">
                        {Array.isArray(details) && details.length > 0 ? (
                          <div className="row">
                            {details.map((account, index) => (
                              <div className="col-lg-3 col-md-4 mb-3" key={index}>
                                <div className="card border-light rounded shadow-sm">
                                  <div className="card-body">
                                    <h5 className="card-title mb-2">Currency: {currencies[account.currency_id] || account.currency_id}</h5>
                                    <p className="card-text">Float Balance:</p>
                                    <h3 className="font-weight-bold text-primary">{account.float_balance}</h3>
                                  </div>
                                  <div className="card-footer bg-light text-muted text-center">
                                    Updated at: {new Date(account.updated_at).toLocaleDateString()}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-center text-muted">No agent accounts found.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* here */}
              </div>
            </div>










            <div className="row">
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-pie me-1" />
                    Transaction Status
                  </div>
                  <div className="card-body">
                    <Pie data={transactionStatusData} width="100%" height={40} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card mb-4">
                  <div className="card-header">
                    <i className="fas fa-chart-line me-1" />
                    Cash Reconciliation
                  </div>
                  <div className="card-body">
                    <Line data={cashReconciliationData} width="100%" height={40} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </main>
      </div>
    </div>
  );
}

export default Main;
