import React from 'react'
import SideNav from '../Components/SideNav'
import ComTransComp from '../Components/ComTransComp'

function ComTrans() {
  return (
    <div>
      <SideNav/>
      <ComTransComp/>
    </div>
  )
}

export default ComTrans
