import React, { useState } from 'react';
import '../Components/Css/InnerBankComp.css';

function InnerBankComp() {
  const [transferDetails, setTransferDetails] = useState({
    senderBranch: '',
    recipientBranch: '',
    amount: '',
    transferReason: ''
  });

  const branches = ['Headquarters', 'Branch A', 'Branch B', 'Branch C'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransferDetails({ ...transferDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the transfer logic here
    console.log('Transfer Details:', transferDetails);
  };

  return (
    <div className="inner-bank-container">
      <h1>Inner Bank Transfer</h1>
      <form onSubmit={handleSubmit} className="transfer-form">
        <div className="form-group">
          <label htmlFor="senderBranch">Sender Branch</label>
          <select
            id="senderBranch"
            name="senderBranch"
            value={transferDetails.senderBranch}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select sender branch</option>
            {branches.map(branch => (
              <option key={branch} value={branch}>{branch}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="recipientBranch">Recipient Branch</label>
          <select
            id="recipientBranch"
            name="recipientBranch"
            value={transferDetails.recipientBranch}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select recipient branch</option>
            {branches.map(branch => (
              <option key={branch} value={branch}>{branch}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            id="amount"
            name="amount"
            value={transferDetails.amount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="transferReason">Reason for Transfer</label>
          <textarea
            id="transferReason"
            name="transferReason"
            value={transferDetails.transferReason}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Submit Transfer</button>
      </form>
    </div>
  );
}

export default InnerBankComp;
