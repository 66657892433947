import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import DepositsComp from '../Components/DepositsComp'

function Deposits() {
  return (
    <div>
    
      <SideNav/>
      <DepositsComp/>
    </div>
  )
}

export default Deposits
