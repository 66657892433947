import React from 'react'
import SideNav from '../Components/SideNav'
import MakeDepComp from '../Components/MakeDepComp'

function MakeDeposit() {
  return (
    <div>
    <SideNav/>
    <MakeDepComp/>  
    </div>
  )
}

export default MakeDeposit
