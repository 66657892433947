import React, { useState } from 'react';
import axios from 'axios';
import { Paper, Typography } from '@mui/material';  

function CashoutComp() {
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleRequestCashout = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('agent_token');
      
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/request_cashout',
        { transaction_id: transactionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );


      if (response.status===200) {
        //setSuccess('Cashout request successful. Confirming cashout...');

        // Confirm cashout after successful request
        const confirmResponse = await axios.post(
          'https://api.nkolozi.com/api/Agent/cashout',
          { transaction_id: transactionId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
console.log(confirmResponse)
        if ( confirmResponse.status===200) {
          setSuccess('Cashout confirmed successfully.');
          console.log(confirmResponse)
        } else {
          setError('Failed to confirm cashout. Please try again.',response);
          console.log(response)
        }
      } else {
        setError('Failed to request cashout. Please try again.', response.data);
        console.log(response)
      }

    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred. Please try again.');
        console.log(error)
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5" style={{ marginLeft: '250px' }}>
      <div className="card">
        <div className="card-header text-center">
        <div style={{ marginLeft: '40%' }}>
 <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          justifyContent: 'center',
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Cashout
        </Typography>
      </Paper>
      </div>
        </div>
        <div className="card-body" >
          <form onSubmit={handleRequestCashout}>
            <div className="mb-3">
              <label htmlFor="transactionId" className="form-label">Transaction ID</label>
              <input
                type="text"
                id="transactionId"
                name="transactionId"
                className="form-control form-control-sm"
                placeholder="Enter Transaction ID"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                required
                style={{
    width: '100%',
    maxWidth: '300px',
  
  }}
              />
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-sm" style={{width: '200px', marginRight:'70%'}} disabled={loading}>
                {loading ? 'Processing...' : 'Request Cashout'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CashoutComp;
