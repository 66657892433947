import React from 'react';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BadgeIcon from '@mui/icons-material/Badge';

function SideNav() {
  return (
    <div id="layoutSidenav" style={{ marginLeft: '20%', marginTop: '4%', width: '75%', position: 'relative' }}>
      <div id="layoutSidenav_nav" style={{ position: 'fixed', top: 58, left: 0, height: '100vh', width: '250px', overflow: 'auto', background: 'linear-gradient(135deg, #003366, #3366cc)' }}>
        <nav className="sb-sidenav accordion" id="sidenavAccordion">
          <div className="sb-sidenav-menu">
            <div className="nav">
              
              <Link to="/dashboard" className="nav-link" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon style={{ color: 'white' }}"><i className="fas fa-tachometer-alt" /></div>
               <div style={{ color: 'white' }}> Dashboard</div>
              </Link>

              <div className="sb-sidenav-menu">
                <div className="nav">
                  <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTransactions"
                    aria-expanded="false"
                    aria-controls="collapseTransactions"
                    style={{ color: 'white' }}
                  >
                    <div className="sb-nav-link-icon">
                      <i className="fas fa-money-bill-alt " ></i>
                    </div>
                   <div style={{ color: 'white' }}> Transactions</div>
                    <div className="sb-sidenav-collapse-arrow">
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div className="collapse" id="collapseTransactions" aria-labelledby="headingTransactions" data-bs-parent="#sidenavAccordion">
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link to="/send" className="nav-link" style={{ color: 'white' }}>Create Cash In</Link>
                      <Link to="/makeDeposit" className="nav-link" style={{ color: 'white' }}>Create Deposit</Link>
                      <Link to="/cashout" className="nav-link" style={{ color: 'white' }}>Create Cash Out</Link>
                    </nav>
                  </div>

                  <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseUserAccounts"
                    aria-expanded="false"
                    aria-controls="collapseUserAccounts"
                    style={{ color: 'black' }}
                  >
                    <div className="sb-nav-link-icon" style={{ color: 'white' }}>
                      <i className="fas fa-user-circle"></i>
                    </div>
                   <div style={{ color: 'white' }}> User Accounts </div>
                    <div className="sb-sidenav-collapse-arrow" style={{ color: 'white' }}>
                      <i className="fas fa-angle-down"></i>
                    </div>
                  </a>
                  <div className="collapse" id="collapseUserAccounts" aria-labelledby="headingUserAccounts" data-bs-parent="#sidenavAccordion">
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link to="/create-user" className="nav-link" style={{ color: 'white' }}>Create User Accounts</Link>
                      {/* <Link to="/find" className="nav-link" style={{ color: 'white' }}>Users</Link> */}
                    </nav>
                  </div>
                </div>
              </div>

              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon"><i className="fas fa-book-open" /></div>
                Financial Management
                <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down" /></div>
              </a>
              <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                  <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                    <nav className="sb-sidenav-menu-nested nav">
                      <Link to="/fees" className="nav-link" style={{ color: 'white' }}>Transaction Fees</Link>
                      <Link to="/exchangeRates" className="nav-link" style={{ color: 'white' }}>Exchange Rates</Link>
                    </nav> 
                  </div>
                  <Link to="/float" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-dollar-sign" /></div>
                    Request Float
                  </Link>
                  <Link to="/convert" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-chart-line" /></div>
                    Change Currency
                  </Link>

                  <Link to="/cintofloat" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-chart-line" /></div>
                    Convert Cashin to Float
                  </Link>
                  {/* <Link to="/manage-commission" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-hand-holding-usd" /></div>
                    Monitor Commissions
                  </Link>
                   {/*
                  <Link to="/manage-cash" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-money-bill-alt" /></div>
                    Manage Cash
                  </Link>
                  <Link to="/comtrans" className="nav-link" style={{ color: 'white' }}>
                    <div className="sb-nav-link-icon"><i className="fas fa-money-bill-alt" /></div>
                    Commission Transactions
                  </Link> */}
                </nav>
              </div>

              <Link to="/reports" className="nav-link" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-chart-bar"></i>
                </div>
                Reports
              </Link>

              <Link to="/settings" className="nav-link" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-cog"></i>
                </div>
                Settings
              </Link>

              <Link to="/logout" className="nav-link" style={{ color: 'white' }}>
                <div className="sb-nav-link-icon">
                  <i className="fas fa-sign-out-alt"></i>
                </div>
                Logout
              </Link>

            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default SideNav;