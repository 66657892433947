import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ClientSelector from './ClientSelector';
import BeneficiarySelector from './BeneficiarySelector';
import { Paper, Typography } from '@mui/material';  

function TransSendComp() {
  const [formData, setFormData] = useState({
    user_id: '',
    currency_id: '',
    beneficiary_id: '',
    initial_transfer_amount: '',
    pay_out_branch: '',
    pay_out_methods: '',
    transaction_reason: '',
    transaction_type: ''
  });
  
  const [currencies, setCurrencies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setFormData((prevData) => ({ ...prevData, user_id: storedUserId }));
    } 

    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/currencies', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          setCurrencies(data.data);
        } else {
          console.error('Unexpected response format:', data);
          setError('Unexpected response format for currencies. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching currencies:', error);
        setError('An error occurred while fetching currencies. Please try again.');
      }
    };

    const fetchBranches = async () => {
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Agent/branchs', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        });
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          setBranches(data.data);
        } else {
          console.error('Unexpected response format:', data);
          setError('Unexpected response format for branches. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
        setError('An error occurred while fetching branches. Please try again.');
      }
    };

    fetchCurrencies();
    fetchBranches();
  }, []);

  const handleClientSelect = (selectedClientId) => {
    setClientId(selectedClientId);
    setFormData((prevData) => ({ ...prevData, user_id: selectedClientId }));
  };

  const handleBeneficiarySelect = (beneficiaryId) => {
    setFormData((prevData) => ({ ...prevData, beneficiary_id: beneficiaryId }));
  };

  const handleCurrencySelect = (e) => {
    setFormData((prevData) => ({ ...prevData, currency_id: e.target.value }));
  };

  const handleBranchSelect = (e) => {
    setFormData((prevData) => ({ ...prevData, pay_out_branch: e.target.value }));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Agent/cashin',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('agent_token')}`
          }
        }
      );
      console.log(formData);
      const data = response.data;
      setLoading(false);

      if (data.status === 'confirm_transaction') {
        setTransactionDetails({
          transaction_id: data.transaction_id,
          total_send_amount: data.total_send_amount,
          transa_usd_value: data.transa_usd_value
        });
      } else {
        alert('Transaction created successfully!');
      }
    } catch (error) {
      console.log(formData);
      console.error('Error creating transaction:', error);
      setError('An error occurred while creating the transaction. Please try again.');
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setIsLoading(true);
    axios.post('https://api.nkolozi.com/api/Agent/confirm-transaction', {
      transaction_id: transactionDetails.transaction_id
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('agent_token')}`
      }
    })
    .then(response => {
      setIsLoading(false);
      alert('Transaction confirmed and successful!');
      setTransactionDetails(null); // Hide the confirmation card after confirmation
    })
    .catch(error => {
      setIsLoading(false);
      console.error('Error confirming transaction:', error);
      alert('Failed to confirm the transaction.', error);
    });
  };

  const handleCancel = () => {
    setIsLoading(true);
    axios.post('https://api.nkolozi.com/api/Agent/cancel-transaction', {
      transaction_id: transactionDetails.transaction_id
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('agent_token')}`
      }
    })
    .then(cancel => {
      setIsLoading(false);
      alert('Transaction Cancelled');
      setTransactionDetails(null);
    })
    .catch(error => {
      setIsLoading(false);
      console.error('Error Cancelling transaction:', error);
      alert('Failed to Cancel The Transaction.');
    });
  };

  return (
    <div className="container mt-4" style={{ marginLeft: '250px' }}>
     <Paper
        sx={{
          padding: '3px 10px', 
          marginBottom: '20px',
          width: 'fit-content', // Makes the paper fit the content
          backgroundColor: '#c5401f', // Set background color
          '@keyframes slide-up': {
            '0%': {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            '100%': {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
          animation: 'slide-up 0.5s ease-in-out',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
          Make A Transaction 
        </Typography>
      </Paper>
      <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow-sm w-75 shadow ">
        <ClientSelector onClientSelect={handleClientSelect} />
        <BeneficiarySelector clientId={clientId} onBeneficiarySelect={handleBeneficiarySelect} />

        <div className="mb-3 form-control">
          <label htmlFor="currency_id" className="form-label">Currency</label>
          <select
            id="currency_id"
            name="currency_id"
            className="form-control"
            value={formData.currency_id}
            onChange={handleCurrencySelect}
            required
          >
            <option value="">--Select Currency--</option>
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name} ({currency.code})
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3 form-control">
          <label htmlFor="pay_out_branch" className="form-label">Pay Out Branch</label>
          <select
            id="pay_out_branch"
            name="pay_out_branch"
            className="form-select form-select-sm"
            value={formData.pay_out_branch}
            onChange={handleBranchSelect}
            required
          >
            <option value="">--Select Branch--</option>
            {branches.map((branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.branch_name} ({branch.city}, {branch.location})
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3 form-control">
          <label htmlFor="initial_transfer_amount" className="form-label">Initial Transfer Amount</label>
          <input
            type="number"
            id="initial_transfer_amount"
            name="initial_transfer_amount"
            className="form-control form-control-sm"
            value={formData.initial_transfer_amount}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
  <label htmlFor="pay_out_methods" className="form-label">Pay Out Methods</label>
  <select
    id="pay_out_methods"
    name="pay_out_methods"
    className="form-control form-control-sm"
    value={formData.pay_out_methods}
    onChange={handleChange}
    required
  >
    <option value="" disabled>Select a method</option>
    <option value="Cashout">Cashout</option>
    <option value="Bank">Bank</option>
  </select>
</div>

        <div className="mb-3 form-control">
          <label htmlFor="transaction_reason" className="form-label">Transaction Reason</label>
          <input
            type="text"
            id="transaction_reason"
            name="transaction_reason"
            className="form-control form-control-sm"
            value={formData.transaction_reason}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3 form-control">
          <label htmlFor="transaction_type" className="form-label">Transaction Type</label>
          <select
            id="transaction_type"
            name="transaction_type"
            className="form-select form-select-sm"
            value={formData.transaction_type}
            onChange={handleChange}
            required
          >
            <option value="">--Select Transaction Type--</option>
            <option value="internal">Internal</option>
            <option value="bank_transfer">Bank Transfer</option>
            <option value="mobile_wallets">Mobile Wallets</option>
          </select>
        </div>

        <button type="submit" className="btn btn-primary btn-sm">
          {loading ? 'Processing...' : 'Submit'}
        </button>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </form>

      {transactionDetails && (
        <div className="mt-4 p-4 bg-light rounded shadow-sm">
          <h4>Transaction Details</h4>
          <p>Transaction ID: {transactionDetails.transaction_id}</p>
          <p>Total Send Amount: {transactionDetails.total_send_amount}</p>
          <p>Transaction USD Value: {transactionDetails.transa_usd_value}</p>
          <div className="mt-3">
            <button className="btn btn-success btn-sm me-2" onClick={handleConfirm} disabled={isLoading}>
              {isLoading ? 'Confirming...' : 'Confirm'}
            </button>
            <button className="btn btn-danger btn-sm" style={{ minWidth: '10px' }} onClick={handleCancel} disabled={isLoading}>
              {isLoading ? 'Cancelling...' : 'Cancel'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TransSendComp;
