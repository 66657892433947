import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import SupportComp from '../Components/SupportComp'

function Support() {
  return (
    <div>
      
      <SideNav/>
      <SupportComp/>
    </div>
  )
}

export default Support
