import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransSendComp from '../Components/TransSendComp'

function TransSend() {
  return (
    <div>

    <SideNav/>
    <TransSendComp/>
    </div>
  )
}

export default TransSend
