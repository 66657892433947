import React, { useState } from 'react';
import '../Components/Css/CreateUserComp.css';
import { Paper, Typography, CircularProgress } from '@mui/material';   
import axios from 'axios';
import { countries } from 'countries-list';

function CreateUserComp() {
  const [userData, setUserData] = useState({
    name: '',
    surname: '',
    lastname: '',
    dob: '',
    gender: '',
    phone1: '',
    national_id: '',
    city: '',
    country: '',
    email: '',
    id_photo: null,
    user_photo: null,
    account_type: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (files) {
      setUserData({ ...userData, [name]: files[0] });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    const countryCode = countries[selectedCountry]?.phone || '';
    setUserData({
      ...userData,
      country: selectedCountry,
      phone1: countryCode ? `+${countryCode}` : '',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    setLoading(true);
    setError(null);

    const formData = new FormData();
    for (const key in userData) {
      formData.append(key, userData[key]);
    }

    try {
      const response = await axios.post('https://api.nkolozi.com/api/Agent/register_client', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('agent_token')}`
        }
      });

      // Handle the response as needed
      console.log('User created:', response.data);
      // Reset form or show success message here
      setUserData({
        name: '',
        surname: '',
        lastname: '',
        dob: '',
        gender: '',
        phone1: '',
        national_id: '',
        city: '',
        country: '',
        email: '',
        id_photo: null,
        user_photo: null,
        account_type: '',
        password: '',
        confirmPassword: ''
      });
    } catch (err) {
      // Handle error here
      setError('Failed to create user. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-user-container">
      <div style={{ marginLeft: '40%' }}>
        <Paper
          sx={{
            padding: '3px 10px', 
            marginBottom: '20px',
            width: 'fit-content',
            backgroundColor: '#c5401f',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Create User
          </Typography>
        </Paper>
      </div>

      <form onSubmit={handleSubmit} className="create-user-form">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          name="name"
          id="name"
          value={userData.name}
          onChange={handleChange}
          required
          className="form-input"
        />
  <label htmlFor="lastname">Midle Name:</label>
        <input
          type="text"
          name="lastname"
          id="lastname"
          value={userData.lastname}
          onChange={handleChange}
          className="form-input"
        />

        <label htmlFor="surname">Surname:</label>
        <input
          type="text"
          name="surname"
          id="surname"
          value={userData.surname}
          onChange={handleChange}
          required
          className="form-input"
        />
      
        <label htmlFor="dob">Date of Birth:</label>
        <input
          type="date"
          name="dob"
          id="dob"
          value={userData.dob}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="gender">Gender:</label>
        <select
          name="gender"
          id="gender"
          value={userData.gender}
          onChange={handleChange}
          required
          className="form-select"
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
        <label htmlFor="country">Country:</label>
        <select
          name="country"
          id="country"
          value={userData.country}
          onChange={handleCountryChange}
          required
          className="form-select"
        >
          <option value="">Select Country</option>
          {Object.entries(countries).map(([code, country]) => (
            <option key={code} value={code}>
              {country.name}
            </option>
          ))}
        </select>
        <label htmlFor="phone1">Phone:</label>
        <input
          type="tel"
          name="phone1"
          id="phone1"
          value={userData.phone1}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="national_id">National ID:</label>
        <input
          type="text"
          name="national_id"
          id="national_id"
          value={userData.national_id}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="city">City:</label>
        <input
          type="text"
          name="city"
          id="city"
          value={userData.city}
          onChange={handleChange}
          className="form-input"
        />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          name="email"
          id="email"
          value={userData.email}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="id_photo">ID Photo:</label>
        <input
          type="file"
          name="id_photo"
          id="id_photo"
          onChange={handleChange}
          className="form-input"
        />
        <label htmlFor="user_photo">User Photo (Optional):</label>
        <input
          type="file"
          name="user_photo"
          id="user_photo"
          onChange={handleChange}
          className="form-input"
        />
        <label htmlFor="account_type">Account Type:</label>
        <input
          type="text"
          name="account_type"
          id="account_type"
          value={userData.account_type}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          name="password"
          id="password"
          value={userData.password}
          onChange={handleChange}
          required
          className="form-input"
        />
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          value={userData.confirmPassword}
          onChange={handleChange}
          required
          className="form-input"
        />

        {/* Loader and error message */}
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}

        <button type="submit" className="form-button" disabled={loading}>
          {loading ? 'Creating...' : 'Create User'}
        </button>
      </form>
    </div>
  );
}

export default CreateUserComp;
